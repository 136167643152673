import BusinessLocation from '@/types/businesslocation';

const findPrimaryLocation = ( locations: BusinessLocation[] ): BusinessLocation => {
    if ( !locations || !locations.length ) {
        return {
            id: 0
            , name: ''
            , addressLine1: ''
            , addressLine2: ''
            , city: ''
            , isW2: false
            , state: ''
            , zip: ''
        };
    }
    if ( locations.length === 1 ) {
        return locations[ 0 ];
    }
    const primaryLocation = locations.find( location => location.isPrimary === true );
    return primaryLocation ? primaryLocation : locations[ 0 ];
};

export default findPrimaryLocation;
